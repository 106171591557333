<template>
  <div class="w-100">
    <!-- Başlık -->
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder text-dark">Mülakat Oluştur</h2>
      <div class="text-gray-400 fw-bold fs-6">
        Mülakat oluşturmak için lütfen soruları oluşturunuz.
      </div>
    </div>

    <!-- Mülakat Başlığı -->
    <div class="mb-10 fv-row">
      <label class="form-label mb-3">Mülakat Başlığı</label>
      <Field
        type="text"
        class="form-control form-control-lg form-control-solid"
        name="interviewTitle"
        placeholder="Ör: Teknik Değerlendirme Mülakatı / ilk Mülakat Aşaması"
      />
      <ErrorMessage
        name="interviewTitle"
        class="fv-plugins-message-container invalid-feedback"
      />
    </div>

    <div class="mb-10 fv-row">
      <label class="form-label mb-3">Mülakat Açıklaması</label>
      <Field
      as="textarea"
        type="text"
        class="form-control form-control-lg form-control-solid"
        name="interviewDesc"
         placeholder="Ör: Bu mülakat, yazılım geliştirme pozisyonu için adayların teknik becerilerini ve deneyimlerini değerlendirir."
      />
      <ErrorMessage
        name="interviewDesc"
        class="fv-plugins-message-container invalid-feedback"
      />
    </div>

    <!-- Mülakat Türü Seç -->
    <div class="mb-0 fv-row">
      <label class="d-flex align-items-center form-label mb-5">
        Mülakat Türü Seç
        <i
          class="fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="tooltip"
          title="Mülakat türünü özelleştirip mülakatlarınızı gerçekleştirebilirsiniz."
        ></i>
      </label>

      <div class="mb-0">
        <!-- Özel Mülakat -->
        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <span class="d-flex align-items-center me-2">
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="/media/icons/duotune/finance/fin001.svg" />
                </span>
              </span>
            </span>
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
                >Özel Mülakat</span
              >
              <span class="fs-6 fw-bold text-gray-400"
                >Tamamen özelleştirilmiş sorular ile mülakat oluşturun.</span
              >
            </span>
          </span>
          <span class="form-check form-check-custom form-check-solid">
            <Field
              class="form-check-input"
              type="radio"
              name="accountPlan"
              value="1"
              @change="showCustomQuestionInput = true"
            />
          </span>
        </label>

        <!-- Star Metodolojisi -->
        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <span class="d-flex align-items-center me-2">
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="/media/icons/duotune/graphs/gra006.svg" />
                </span>
              </span>
            </span>
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
                >Star Metodolojisi</span
              >
              <span class="fs-6 fw-bold text-gray-400"
                >Star metodolojisi ile adayınızdan kendini tanıtmasını ve
                mesleğe bakış açısını anlatmasını isteyin</span
              >
            </span>
          </span>
          <span class="form-check form-check-custom form-check-solid">
            <Field
              class="form-check-input"
              type="radio"
              name="accountPlan"
              value="2"
              @change="showCustomQuestionInput = false"
            />
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-2",
  components: {
    Field,
    ErrorMessage,
  },
  setup() {
    const today = new Date().toISOString().split("T")[0];
    const startDate = ref(today);
    const endDate = ref("");
    const showCustomQuestionInput = ref(false);
    const showModal = ref(false); // Modal kontrolü

    // Yeni soru nesnesi
    const newQuestion = ref({
      text: "",
      type: "open", // Varsayılan olarak açık uçlu
      options: [] as string[],
    });

    // Eklenen sorular listesi
    const questions: any = ref([]);

    // Yeni opsiyon metni
    const newOptionText = ref("");

    // Soru ekleme fonksiyonu
    const addQuestion = () => {
      if (newQuestion.value.text) {
        questions.value.push({
          ...newQuestion.value,
        });
        newQuestion.value = { text: "", type: "open", options: [] };
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    // Opsiyon silme fonksiyonu
    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    return {
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      showCustomQuestionInput,
      today,
      startDate,
      endDate,
    };
  },
});
</script>

<style scoped>
.list-group-item {
  border: none;
  padding: 10px 15px;
  background-color: #f8f9fa;
  margin-bottom: 5px;
  border-radius: 4px;
}
</style>
