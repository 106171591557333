<template>
  <div class="w-100">
    <!-- Başlık -->
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder text-dark">Soruları Tanımla</h2>
      <div class="text-gray-400 fw-bold fs-6">
        Lütfen mülakat sorularını ekleyiniz ve pozisyona göre özelleştiriniz.
      </div>
    </div>

    <div
      v-if="questions.length === 0"
      class="
        notice
        d-flex
        bg-light-warning
        rounded
        border-warning border border-dashed
        mb-10
        p-6
      "
    >
      <div class="d-flex flex-stack flex-grow-1">
        <div class="fs-6 fw-bold">
          Lütfen mülakat oluşturmak için mülakat soruları giriniz.
        </div>
      </div>
    </div>

    <div class="mb-10 fv-row">
      <div class="text-center my-4">
          <button
      class="btn btn-primary mb-4"
      type="button"
      @click="showModal = true"
    >
      Mülakata Soru Ekle
    </button>
      </div>
    </div>

    <!-- Soru Ekleme Butonu -->
    

    <div
      v-if="showModal"
      class="modal fade show"
      tabindex="-1"
      style="display: block; background: rgba(0, 0, 0, 0.5);"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Yeni Soru Ekle</h5>
            <button type="button" class="btn-close" @click="closeModal"></button>
          </div>
          <div class="modal-body">
            <!-- Soru Yazma Inputu -->
            <div class="mb-3">
              <label class="form-label">Soru</label>
              <textarea
                          class="
                            form-control form-control-lg form-control-solid
                            me-3
                          "
                          v-model="newQuestion.text"
                          @keyup.enter="addQuestion"
                          placeholder="Sorunuzu yazın ve Enter'a basın"
                          style="flex-grow: 1"
                        ></textarea>
            </div>

            <!-- Soru Tipi Seçme -->
            <div class="mb-3">
              <label class="form-label">Soru Türü</label>
              <select v-model="newQuestion.type" class="form-select">
                <option value="open">Açık Uçlu</option>
                <option value="single">Tek Seçenekli</option>
                <option value="multiple">Çok Seçenekli</option>
              </select>
            </div>

            <!-- Opsiyon Ekleme -->
            <div v-if="newQuestion.type !== 'open'" class="mb-3">
              <label class="form-label">Opsiyon Ekle</label>
              <div class="d-flex">
                <input
                          type="text"
                          v-model="newOptionText"
                          @keyup.enter="addOption"
                          class="form-control me-3"
                          placeholder="Opsiyon eklemek için yazın ve Enter'a basın"
                          v-on:keydown.enter.prevent
                        />
                <button class="btn btn-secondary ms-2" type="button" @click="addOption">
                  Ekle
                </button>
              </div>
              <ul class="list-group mt-2">
                <li
                  v-for="(option, index) in newQuestion.options"
                  :key="index"
                  class="list-group-item d-flex justify-content-between"
                >
                  {{ option }}
                  <button
                    type="button"
                    @click="removeOption(index)"
                    class="btn btn-sm btn-danger"
                  >
                    Sil
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" @click="closeModal">
              İptal
            </button>
            <button class="btn btn-primary" @click="addQuestion">
              Soruyu Ekle
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Eklenen Sorular -->
    <div v-if="questions.length > 0" class="mt-5">
      <h3 class="form-label">Eklenen Sorular</h3>
      <ul class="list-group">
        <li
          v-for="(question, index) in questions"
          :key="index"
          class="list-group-item d-flex justify-content-between align-items-start"
        >
          <div>
            <strong>Soru:</strong> {{ question.text }}<br />
            <strong>Tür:</strong>
            {{ question.type === 'open' ? 'Açık Uçlu' : question.type === 'single' ? 'Tek Seçenekli' : 'Çok Seçenekli' }}
            <ul v-if="question.options.length > 0" class="mt-2">
              <li v-for="(option, i) in question.options" :key="i">{{ option }}</li>
            </ul>
          </div>
          <button type="button" @click="removeQuestion(index)" class="btn btn-sm btn-danger">
            Sil
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "step-3",
  setup() {
    const showModal = ref(false);

    const newQuestion = ref({
      text: "",
      type: "open",
      options: [] as string[],
    });

    const newOptionText = ref("");
    const questions = ref<any[]>([]);

    const addQuestion = () => {
      if (newQuestion.value.text.trim() !== "") {
        questions.value.push({ ...newQuestion.value });
        newQuestion.value = { text: "", type: "open", options: [] };
        showModal.value = false;
      }
    };

    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    const closeModal = () => {
      showModal.value = false;
    };

    return {
      showModal,
      newQuestion,
      newOptionText,
      questions,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      closeModal,
    };
  },
});
</script>

<style scoped>
.modal {
  z-index: 1050;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.list-group-item {
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 5px;
}
</style>
