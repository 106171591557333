
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "step-3",
  setup() {
    const showModal = ref(false);

    const newQuestion = ref({
      text: "",
      type: "open",
      options: [] as string[],
    });

    const newOptionText = ref("");
    const questions = ref<any[]>([]);

    const addQuestion = () => {
      if (newQuestion.value.text.trim() !== "") {
        questions.value.push({ ...newQuestion.value });
        newQuestion.value = { text: "", type: "open", options: [] };
        showModal.value = false;
      }
    };

    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    const closeModal = () => {
      showModal.value = false;
    };

    return {
      showModal,
      newQuestion,
      newOptionText,
      questions,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      closeModal,
    };
  },
});
