<template>
  <!--begin::Wrapper-->
  <div class="w-100">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-15">
      <!--begin::Title-->
      <h2 class="fw-bolder d-flex align-items-center text-dark">
        İş İlanı Seç
        <i
          class="fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="tooltip"
          title="Hangi pozisyon için mülakat oluşturmak istediğinizi tanımlayın."
        ></i>
      </h2>
      <!--end::Title-->

      <!--begin::Notice-->
      <div class="text-gray-400 fw-bold fs-6">
        Mülakat oluşturmak için açık pozisyon seç.
      </div>
      <!--end::Notice-->
    </div>
    <!--end::Heading-->

    <!--begin::Input group-->
    <div class="mb-10 fv-row">
      <!--begin::Label-->
      <label class="form-label mb-3">İş İlanı:</label>
      <!--end::Label-->

      <!--begin::Select-->
      <select
        name="status"
        data-control="select2"
        data-hide-search="true"
        class="
          form-select form-control form-select-md form-control-solid
          fw-bolder
          mb-1
        "
      >
        <option value="Active" selected>Seçiniz...</option>
        <option value="Active">Hybrid Çalışan Frontend Developer</option>
        <option value="Approved">Hybrid Çalışan Backend Developer</option>
        <option value="Declined">Ofisten Çalışan Fullstack Developer</option>
        <option value="Declined">Hybrid Çalışan Software Specialist</option>
      </select>
      <!--end::Select-->
    </div>
    <!--end::Input group-->

    <div class="text-center my-4">
      <span class="fw-bold text-gray-900">YA DA</span>
    </div>

    <!--begin::Input group-->
    <div class="mb-10 fv-row">
      <div class="text-center my-4">
        <router-link to="/interview/new-jobposting">
          <button type="button" class="btn btn-success">
            Yeni İlan Oluştur
          </button></router-link
        >
      </div>
    </div>
    <!--end::Input group-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { VueEditor } from "vue3-editor";

export default defineComponent({
  name: "step-1",
  components: {
    Field,
    ErrorMessage,
    VueEditor,
  },
  setup() {
    const positionName = ref("");
    const positionDesc = ref("");
    const featureInput = ref("");
    const features = ref<string[]>([]);

    const addFeature = () => {
      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = "";
      }
    };

    const editorOptions = ref({
      theme: "snow", // Quill editor tema ayarı
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ align: [] }],
          ["link", "image"],
          ["clean"],
        ],
      },
    });

    return {
      positionName,
      positionDesc,
      featureInput,
      features,
      addFeature,
      editorOptions,
    };
  },
});
</script>
