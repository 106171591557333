<template>
  <div class="w-100" @dragover.prevent="preventScroll">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-12">
      <h2 class="fw-bolder text-dark">Mülakatları Sırala</h2>
      <div class="text-gray-400 fw-bold fs-6">
        Seçmiş olduğun iş ilanındaki mülakatlara göre öncelik sırasını belirle.
      </div>
    </div>

    <!-- Mülakatları sıralama alanı -->
    <div class="d-grid gap-4 grid-columns-4">
      <div
        v-for="(interview, index) in interviews"
        :key="interview.id"
        class="interview-card"
        :class="{
          dragging: index === draggedIndex,
          'drag-over': index === dragOverIndex,
        }"
        draggable="true"
        @dragstart="onDragStart(index)"
        @dragover.prevent="onDragOver(index)"
        @dragleave="onDragLeave"
        @dragend="onDragEnd"
        @drop="onDrop(index)"
      >
        <div class="interview-content">
          <div class="interview-order">{{ index + 1 }}</div>
          <h3 class="fw-bold">{{ interview.title }}</h3>
          <p class="text-gray-500">{{ interview.description }}</p>
        </div>
        <span v-if="interview.type == 'new'" class="badge badge-light-success"
          >Yeni</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "step-4",
  setup() {
    const interviews = ref([
      {
        id: 1,
        title: "Teknik Mülakat",
        description: "Bu mülakat adayın teknik bilgilerini ölçer.",
        type: "old",
      },
      {
        id: 2,
        title: "Yabancı Dil Mülakatı",
        description: "Bu mülakat adayın yabancı dil seviyesini ölçer.",
        type: "old",
      },
      {
        id: 3,
        title: "Yazılım Test Mülakatı",
        description: "Bu mülakat adayın test becerilerini ölçer.",
        type: "new",
      },
      {
        id: 4,
        title: "Star Mülakat",
        description:
          "Bu mülakat adayın deneyimlerini ve kariyerine olan bakış açısını ölçer.",
        type: "old",
      },
    ]);

    const draggedIndex = ref<number | null>(null);
    const dragOverIndex = ref<number | null>(null);

    const onDragStart = (index: number) => {
      draggedIndex.value = index;
    };

    const onDragOver = (index: number) => {
      dragOverIndex.value = index;
    };

    const onDragLeave = () => {
      dragOverIndex.value = null;
    };

    const onDrop = (index: number) => {
      if (draggedIndex.value !== null && draggedIndex.value !== index) {
        const draggedItem = interviews.value[draggedIndex.value];
        interviews.value.splice(draggedIndex.value, 1);
        interviews.value.splice(index, 0, draggedItem);
      }
      cleanDragState();
    };

    const onDragEnd = () => {
      cleanDragState();
    };

    const cleanDragState = () => {
      draggedIndex.value = null;
      dragOverIndex.value = null;
    };

    // Scroll kaymasını engellemek için
    const preventScroll = (event: DragEvent) => {
      event.preventDefault();
    };

    return {
      interviews,
      onDragStart,
      onDragOver,
      onDragLeave,
      onDrop,
      onDragEnd,
      preventScroll,
      draggedIndex,
      dragOverIndex,
    };
  },
});
</script>

<style scoped>
/* Grid Layout */
.d-grid {
  display: grid;
  gap: 16px;
}

.grid-columns-4 {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.interview-card {
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
  box-sizing: border-box;
  transition: none; /* Geçiş animasyonunu iptal et */
}

/* Sürükleme Sırasında Stil */
.dragging {
  opacity: 0.5;
}

.drag-over {
  outline: 2px dashed #007bff; /* Border yerine outline kullanıldı */
  background-color: #e2e6ea;
}

/* Kart içerisindeki içerik */
.interview-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* Sıra İbaresi */
.interview-order {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
