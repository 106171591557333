
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "step-4",
  setup() {
    const interviews = ref([
      {
        id: 1,
        title: "Teknik Mülakat",
        description: "Bu mülakat adayın teknik bilgilerini ölçer.",
        type: "old",
      },
      {
        id: 2,
        title: "Yabancı Dil Mülakatı",
        description: "Bu mülakat adayın yabancı dil seviyesini ölçer.",
        type: "old",
      },
      {
        id: 3,
        title: "Yazılım Test Mülakatı",
        description: "Bu mülakat adayın test becerilerini ölçer.",
        type: "new",
      },
      {
        id: 4,
        title: "Star Mülakat",
        description:
          "Bu mülakat adayın deneyimlerini ve kariyerine olan bakış açısını ölçer.",
        type: "old",
      },
    ]);

    const draggedIndex = ref<number | null>(null);
    const dragOverIndex = ref<number | null>(null);

    const onDragStart = (index: number) => {
      draggedIndex.value = index;
    };

    const onDragOver = (index: number) => {
      dragOverIndex.value = index;
    };

    const onDragLeave = () => {
      dragOverIndex.value = null;
    };

    const onDrop = (index: number) => {
      if (draggedIndex.value !== null && draggedIndex.value !== index) {
        const draggedItem = interviews.value[draggedIndex.value];
        interviews.value.splice(draggedIndex.value, 1);
        interviews.value.splice(index, 0, draggedItem);
      }
      cleanDragState();
    };

    const onDragEnd = () => {
      cleanDragState();
    };

    const cleanDragState = () => {
      draggedIndex.value = null;
      dragOverIndex.value = null;
    };

    // Scroll kaymasını engellemek için
    const preventScroll = (event: DragEvent) => {
      event.preventDefault();
    };

    return {
      interviews,
      onDragStart,
      onDragOver,
      onDragLeave,
      onDrop,
      onDragEnd,
      preventScroll,
      draggedIndex,
      dragOverIndex,
    };
  },
});
